* {
  margin: 0px;
  box-sizing: border-box;
}

:root {
  --primary-bg: #f7f7f7;
  --primary-color: #223e92;
  --primary-text: #363636;
}

body {
  background: var(--primary-bg);
  font-family: "Lato", "Roboto", "Helvetica Neue";
  color: var(--primary-text);
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  color: var(--primary-text);
}
a {
  /* color: inherit; */
  text-decoration: none;
}
a:hover{
  /* color: inherit; */
}
.w-47{
  width: 47%!important;
}
/* common styles */
.first-letter-cap::first-letter {
  text-transform:uppercase;
}
.font-small {
  font-size: 13px;
}
.font-12{
font-size: 12px;
}
.width-auto {
  width: auto !important;
}
.w-30{
  width: 30% !important;
}
.w-40{
  width: 40% !important;
}
.w-10{
  width: 10% !important;
}
.w-20{
  width: 20% !important;
}
.justify-unset{
  justify-content: unset!important;
}
.w-70{
  width: 70% !important;
}
.w-240{
  width: 240px !important;
}
.w-140{
  width: 140px !important;
}
.align-base{
  align-items: baseline!important;
}
.underline {
  text-decoration: underline;
}
a:hover{
  text-decoration: none!important;
}
.w-47{
  width:47%;
}
.d-grid{
  /* width:47%; */
  display: grid;
}
.border-outline{
  border-left: 1px solid #EBEBEB;
}
.primary-text {
  color: var(--primary-color);
}
.font-md{
font-size: 17px!important;
}
.text-grey{
  color:#9f9898!important;
}
.text-underline{
  text-decoration:underline;
}
.primary-text{
  color:var(--primary-color)!important;
}
.included-text{
  font-weight: bold;
  color:var(--primary-color)!important;
}
.font-sm{
  font-size: 16px;
}
.small-text{
  font-size: 12px;
}
.info-icon{
  border-radius: 50%;
    border: 1px solid;
    padding: 4px 10px;
    font-size: 20px;
    margin: 0px 10px 0px 0px;
}
.bg-grey{
  background:#d3d3d35c!important;
}
.space-between {
  justify-content: space-between;
}
.checkbox-wrapper {
  flex-direction: row !important;
  align-items: center;
}
/* margin starts */
.margin-y {
  margin: 1rem 0;
}
.margin-x {
  margin: 0 1rem;
}
.margin-l {
  margin-left: 1rem;
}
.margin-t {
  margin-top: 1rem;
}
.margin-r {
  margin-right: 1rem;
}
.margin-b {
  margin-bottom: 1rem;
}
/* margin ends */
.justify-end{
  justify-content: flex-end !important;
}
.error{
  color:red;
  font-size: 12px;
}
/* button styles */
.button-close{
  min-width: 100px;
  border: 0px solid;
  background-image: linear-gradient(red, #b13b3b);
  color: white;
}
.button-close:focus{
  outline: none!important;
  border: 0px solid;
}
.button-primary {
  border: 2px solid #195729;
  color: #fff;
  background: #238a3e;
}
.button-secondary {
  border: 2px solid #dddddd;
  color: var(--primary-color);
  background: #f7f7f7;
}
.text-icon{
  color: var(--primary-color);
}
/* common style ends */

/* Header  starts*/
.selected-lang {
  background: var(--primary-color) !important;
  color: #f7f7f7 !important;
  cursor: default !important;
  border-bottom: 1px solid red!important;
}
/* Header ends */

/* Forms section starts */
.selected-bar {
  border: 1.5px solid #eb1b2e !important;
  margin-right: 5px !important;
  background-color: #eb1b2e !important;
}
.street-field {
  width: 77% !important;
}
.housenum-field {
  width: 18% !important;
  margin-right: 0 !important;
}
.city-field {
  width: 53% !important;
}
.country-field {
  width: 20% !important;
  margin-right: 0 !important;
}
.form-step2-gender-field {
  width: 126px !important;
}
.date-month-field {
  width: 25% !important;
}
.date-month-field:nth-child(2) {
  margin: 0px 25px;
}
.upgrade-card-text {
  font-size: 13px !important;
  margin: 12px 0px !important;
}
.note{
  font-size:1px;
  margin:0.3rem;
  text-align:right;
}
.terms{
  margin:0px 5px 0.5rem 5px;
}
.terms-url{
  color: black;
}
.readMoreText{
  display: block;
  margin: 10px 0px;
  /* text-decoration: underline; */
  color: var(--primary-text)!important;
}
.short-text{
  display: block;
  margin: 20px 0px;
  word-break: break-all;
}
.invisible{
  opacity:0;
}
.step-0-continue{
  width: 25%;
  margin-left: 25px;
}
/* .word-break{
  word-break: break-all;
} */
/* Forms section ends */
/* selected package starts */
.no-of-travelers {
  margin: 8px;
}
/* hide arrow marks */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* hide arrow mars number */
/* help sections */
.modal-overlay{
  background: #223e92f2;
}
.modal-container{
  max-width: 600px;
  background:transparent;
  box-shadow: none;
  margin: 0 auto;
  /* min-width: 600px; */
}
.modal-close-btn{
  color:white;
  right:-100px!important;
}
.modal-passengers-overlay{
  background: #fff;
}
.modal-passengers-container{
  max-width: 600px;
  background:transparent;
  box-shadow: none;
  margin: 0 auto;
  /* min-width: 600px; */
}
.modal-close-btn{
  color:white;
}
/* help sections */
.infrm-name{
  display:flex;
  justify-content: flex-end;
  align-items: center;
}
.mbtm-10{
  margin-top: 25px;
}
.align-center{
  align-items: center;
}
/* label{
  margin-bottom: 0!important;
} */
.page-border{
  text-align: center;
  word-break: break-all;
  border-right: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  padding: 0px 10px;
  margin: 0px 15px;
}
.page-border-right-0{
border-right:0px;
}
.page-border-left-0{
  border-left:0px;
  }
  .insurance-popover{
    max-width: 400px!important;
    overflow: initial!important;
    /* left: 468px!important; */
  }
/* TABLE */
tbody th {
  background: transparent
    linear-gradient(0deg, #c91c2c 0%, #da1c2e 36%, #eb1c2f 100%) 0% 0% no-repeat
    padding-box;
  color: #fff !important;
}
tbody .MuiTableRow-root:nth-child(odd) {
  background: #dddddd;
}
.MuiTable-root {
  width: 749px!important;
  /* margin: 0 0 0 auto!important; */
  overflow-x: auto;
}
#price-cell{
  text-align: center!important;
}
.green-border{
  border-bottom:2px solid #096109!important;
  border: 2px solid #096109!important;
}
.days-border{
  border-bottom: 0 !important;
}
.MuiPaper-root{
  background: transparent!important;
}
.MuiTableCell-root {
  line-height: 0.5em !important;
  padding: 20px 18px !important;
}
.MuiPaper-elevation1 {
  box-shadow: 0px 0px !important;
}
.MuiTableHead-root {
  background: var(--primary-bg);
}
.another-date {
  justify-content: flex-end !important;
  text-decoration: underline;
}
/* TABLE ENDS */
/* selected package ends */

/* overwrite starts */
/* dropdown  */
.css-1okebmr-indicatorSeparator {
  display: none;
}

/* checkbox starts */
.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.form-group{
  margin-bottom: 0 !important;
}
.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #d8d8d8;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.form-group input:checked + label:before {
  background: var(--primary-color);
  border: 2px solid var(--primary-color);

}

/* checkbox starts */
.checkbox-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.checkbox-group{
  margin-bottom: 0 !important;
}
.checkbox-group label {
  position: relative;
  cursor: pointer;
}

.checkbox-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid red;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}
.checkbox-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
#check-box-id::before{
  box-shadow: none!important;
}

.checkbox-group input:checked + label:before {
  background: red;
  border: 2px solid red;
}
 

/* overwrite ends */
.drop-down {
  min-width: 150px;
}
.package-table {
  min-width: 100;
}
.c-pointer {
  cursor: pointer;
}
.package-border{
  border-right: 1px solid #b7b7b7;
  padding-right: 20px;
}
.package-last {
  position: relative;
  right: 14%;
}
.model-close-btn {
  filter: invert(1);
}
.grey-text{
  color:lightgrey!important;
}
.fl-r {
  float: right;
}
.p-info-msg {
  line-height: 1em;
  text-decoration: underline;
  font-size: 13px;
  margin: 0px 0 16px 1.9rem;
  width: 200px;
  color: #616161;
}
.loader-bold-text{
  font-weight: bolder;
    /* color#223E92: #223E92; */
    color: #223E92;
    font-size: 20px;
}
.loader-text{
  font-size: 14px;
}
.err-mr-2 {
  margin-right: 2.3rem;
  float :right;
}


/* navbar i hamburger con */
 /* navbar */
 .nav-btn{
  display: none;
  padding: 10px 0px;
  z-index: 5;
}
.hamburger .line,.is-active .line{
  width: 40px;
  height: 5px;
  background-color:var(--primary-color);
  display: block;
  margin: 8px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover{
  cursor: pointer;
}
.is-active .line:nth-child(2){
  opacity: 0;
}

.is-active .line:nth-child(1){
  -webkit-transform: translateY(13px) rotate(45deg);
  -ms-transform: translateY(13px) rotate(45deg);
  -o-transform: translateY(13px) rotate(45deg);
  transform: translateY(13px) rotate(45deg);
}

.is-active .line:nth-child(3){
  -webkit-transform: translateY(-13px) rotate(-45deg);
  -ms-transform: translateY(-13px) rotate(-45deg);
  -o-transform: translateY(-13px) rotate(-45deg);
  transform: translateY(-13px) rotate(-45deg);
}

.mobile-more-passengers{
  display:none;
}
.web-more-passengers{
  display:block;
}
.triangle-down{
  display:block;
  width: 0;
  height: 0;
  margin: 0 auto;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 15px solid #EBEBEB;
}
.triangle-down-hr{
  margin-bottom: 0px!important;
}
.copyright{
  white-space:pre;
}
@media screen and (max-width: 1024px) {
  .modal-close-btn{
    right: 0!important;
  }
}
@media screen and (min-width: 1320px) {
  .MuiTable-root {
    width: 100%!important;
    /* margin: 0 0 0 auto!important; */
    overflow-x: auto;
  }
}
@media screen and (max-width: 768px) {
  .step-0-continue{
    width: auto;
    margin:10px 0 0 0!important;
  }
  .insurance-popover{
    max-width: 400px!important;
    overflow: initial!important;
    left: 173px!important;
  }
  .summary-btn-wrapper{
    flex-direction: column;
  }
  .nav-btn{
    display: inline-block;
  }
  .app-logo{
    width: auto!important;
  }
  .web-more-passengers{
    display:none;
  }
  .mobile-more-passengers{
    display:block;
  }
  .hr-none{
    display:none;
  }
  /* more than 4 */
  .more-passengers-container{
    flex-direction: column;
  }
  .more-passengers-single-section{
    width:100%!important;
    margin:0px!important;
  }
  .more-passengers-margin{
    margin:10px 0px!important;
  }
  .traveleres-section{
    flex-direction: column;
  }
  
  .travelers-dates{
    margin: 15px 0px;
  }

 
  .step-zero-package-section{
    width: 100%!important;
  }
}
@media screen and (max-width: 400px) {
  /* four-steps */
  .insurance-popover{
    max-width: 300px!important;
    overflow: initial!important;
    left: 15%!important;
  }
}
@media screen and (max-width: 600px) {
  /* four-steps */
  .insurance-popover{
    max-width: 300px!important;
    overflow: initial!important;
    left: 0%!important;
  }
  /* step 0 */
.total-price-note{
  text-align: left!important;
}
.step-0-continue{
  width:100%;
  margin:10px 0 0 0!important;
}
.MuiTableCell-root {
  border-bottom: 0 !important;
  line-height: unset;
  padding: 15px 10px !important;
}
.another-date {
  justify-content:start!important;
  text-decoration: underline;
}
.traveleres-section{
  flex-direction: column;
}
.travelers-col{
  width: 100%!important;
}
.travelers-col1{
  width: 60%!important;
}
.w-auto-768{
  width: auto!important;
}

.page-border{
  margin: 0;
  border: none;
  padding:0;
}

.summary-btn{
  align-self: flex-end;
}
/* step four */
.step-four-passenger-details{
  flex-direction: column;
  align-items: baseline!important;
}
.step-four-single-section{
border-left: 0px!important;
width:100%!important;
padding:0!important;
}
.step-four-packages{
  flex-direction: column;
  align-items: baseline!important;
  margin: 8px 0px;
}
.step-four-packages p{
  margin: 0!important;
}
.step-four-align-self{
  align-self: center!important;
}
  /* four steps ends */
  .status-child-div {
    width: auto !important;
  }
}
@media screen and (max-width: 555px) {
  /* forms step one input field */
  .step-one-flexwrapper{
    flex-direction: column;
  }
  .step-one-input-container{
    width: 100%!important;
  }
  .step-one-invoice{
    flex-direction: column;
    align-items: baseline!important;
  }
  /* forms step one input field */
}
@media screen and (max-width: 425px) {
.departure-date{
 flex-direction: column; 
}
.margin-425{
  margin: 15px 0px;
}
}

table.MuiTable-root {
  /* display: block; */
  width: 100% !important;
}

/* table.MuiTable-root thead {
  display: block;
} */

table.MuiTable-root thead tr th {
  width: auto !important;
  white-space: nowrap;
}

table.MuiTable-root tbody tr th, table.MuiTable-root tbody tr td {
  width: auto !important;
  white-space: nowrap;
}

/* table.MuiTable-root tbody {
  display: block;
} */